export interface DocumentMetadataTypes {
  [p: string]: any;
}

export interface UploadDocumentData {
  filename: string;
  fileExtension: string;
  fileSize: number;
  documentId: string;
  isNewDocument: boolean;
}

export interface CrawlWebsiteData {
  url: string;
  documentId: string;
  isNewDocument: boolean;
}

export enum RepositoryType {
  Documents = 'documents',
  Websites = 'websites',
}
export const RepositoryTypeAttributes = {
  [RepositoryType.Documents]: { documentLocator: 'filename' },
  [RepositoryType.Websites]: { documentLocator: 'url' },
};
